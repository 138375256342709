<template>
  <div id="app">
    <div class="IRouter">
      <div class="homeLogo"><img src="/img/logo1.jpg" /></div>
      <nav>
        <router-link to="/">
          <div class="routerItem">首页</div>
        </router-link>
        <router-link to="/about?A=1">
          <div class="routerItem">实景案例</div>
        </router-link>
        <router-link to="/about?A=2">
          <div class="routerItem">设计师</div>
        </router-link>
        <router-link to="/about?A=3">
          <div class="routerItem">工程施工</div>
        </router-link>
        <router-link to="/about?A=4">
          <div class="routerItem">优选整装</div>
        </router-link>
        <router-link to="/about?A=5">
          <div class="routerItem">关于我们</div>
        </router-link>
      </nav>
      <div class="topPhone">
        <img src="/img/phoneIcon.png"  />
        <span> 18293837210</span>
      </div>
      <div style="clear: both"></div>
    </div>
    <router-view />
    <div class="futter">
      <div class="futter2">
        <div class="futter3 first">
          <router-link to="/">关于我们</router-link>
        </div>
        <div class="futter3"><router-link to="/">企业文化</router-link></div>
        <div class="futter3"><router-link to="/">联系我们</router-link></div>
      </div>
      <div class="futter2">
        <div class="futter3 first">
          <router-link to="/">装修服务</router-link>
        </div>
        <div class="futter3"><router-link to="/">找设计师</router-link></div>
        <div class="futter3"><router-link to="/">找施工队</router-link></div>
      </div>
      <div class="futter2">
        <div class="futter3 first">
          <router-link to="/">案例展示</router-link>
        </div>
        <div class="futter3"><router-link to="/">整包案例</router-link></div>
        <div class="futter3"><router-link to="/">改造案例</router-link></div>
      </div>
      <div class="futter2">
        <div class="futter3 first">
          <router-link to="/">土木乡野APP</router-link>
        </div>
        <div class="futter3"><router-link to="/">下载APP</router-link></div>
        <div class="futter3"><router-link to="/">AI免费设计</router-link></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "YourComponent",
  mounted() {
    const script = document.createElement("script");
    script.src = "/js/rem.js"; // 替换为你的脚本URL
    script.onload = () => {
      // 脚本加载完毕后的回调函数
      console.log("Script loaded successfully.");
    };
    document.body.appendChild(script);
  },
};
</script>
<style>
body {
  padding: 0px;
  margin: 0px;
  min-width1111111: 1100px;
}

#app {
  font-size: 20px;
  font-family: PingFang HK-Regular, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.IRouter {
  height: 82px;
  overflow: hidden;
}

.homeLogo {
  float: left;
  padding-left: 1.51rem;
  padding-top: 31px;
}
@media screen and (max-width: 1100px) {
  .homeLogo1111 {
    padding-left: 0.51rem;
  }
  nav1111 {
    margin-left: 0.5rem !important;
  }
}
.routerItem {
  float: left;
  font-size: 0.21rem;
  line-height: 47px;
  margin-left: 0.4rem;
  padding: 0px 10px 0px 10px;
}
.topPhone {
  float: right;
  padding-right: 0.52rem;
  font-size: 0.24rem;
  font-weight: 600;
  color: #ff6c00;
  padding-top: 40px;
}
.topPhone  img{
  width: 0.19rem;
  height: 0.19rem;
}
nav {
  float: left;
  padding-top: 30px;
  margin-left: 1rem;
}

nav a {
  text-decoration: none;
  color: #333333;
  padding-top: 0.55rem;
}

nav a.router-link-exact-active .routerItem {
  border-bottom: 5px solid #1c9a0f;
}

.futter {
  margin-top: 0.78rem;
  font-family: Source Han Sans CN VF, Source Han Sans CN VF;
  width: 19.2rem;
  height: 2.73rem;
  background: #1f1f1e;
  color: #ffffff;

  line-height: 0.5rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.futter .futter2 {
  float: left;
  width: 4.8rem;
  padding-top: 0.69rem;
}
.futter .futter2 .futter3 {
  padding-left: 1.1rem;
  opacity: 0.7;
  font-size: 0.19rem;
}
.futter .futter2 .first {
  font-size: 0.25rem;
  opacity: 1;
}
.futter .futter2 a {
  text-decoration: none;
  color: #ffffff;
}
</style>
