import axios from "axios"

const create = function(targetId, targetType, areasize, name, phone){
    return axios.post('/app-api/business/reservation-log/create',{
        targetId: targetId,
        targetType: targetType,
        areasize: areasize,
        name: name,
        phone: phone
    },{ headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        //'Authorization': 'Bearer 4dfe34ff8dfc4ed0b5459fc36ae63805'
    }});
}

/**预约记录 */
export default {
    create
}